.outlet {
  width: 100%;
}
.main_Heading {
  width: 90%;
  max-width: 1020px;
  font-size: x-large;
  margin: 20px 0px;
}
.modalOverlay {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.897);
  display: flex;
  justify-content: center;
  z-index: 9999;
  padding: 10px;
  /* overflow: auto; */
}

.modal {
  position: relative;
  width: 90%;
  height: 90%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}


.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: center;
  font-size: 14px;
  width: 100%;
  max-width: 1020px;
  /* margin: 0px 26px; */
  /* margin-bottom: 20px; */
  border-bottom: 2px solid rgb(130, 129, 129);
}
.user_main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.month-div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.options {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
}
.flexRow {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 0px;
  gap: 5px;
}
.flexRow button {
  display: flex;
  gap: 4px;
  align-items: center;
}
.selectBox {
  border: 1px solid #d1d5db;
  padding: 2px;
  font-size: 12px;
  border-radius: 4px;
  color: black;
}

.productivity_main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.productivity_div {
  display: flex;
  gap: 5px;
}
.productivity_inner_div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.activity-section {
  overflow-x: auto;
  padding: 20px;
  /* min-width: 768px; */
}
.table-container {
  overflow-x: auto;
  white-space: nowrap;
}
.activity-table {
  width: 100%;
  min-width: 600px;
  border-collapse: collapse;
}
.activity-table tbody {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* border-left: 1px solid #e0e0e0; */
  padding-left: 10px;
}
.table_row {
  display: block;
}
.activity-first-cell {
  position: relative;
  font-size: smaller;
  display: flex;
  gap: 10px;
}
.circleDiv {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: absolute;
  left: -25px;
  top: 5px;
}
.time_main1 {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.time_main2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 8px;
}
.time_main3 {
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.activity-cell {
  height: 130px;
  font-size: smaller;
}

.activity_img {
  background-color: rgb(194, 193, 193);
  border-radius: 5px;
  height: 90px;
  /* margin-top: 10px; */
  width: 100%;
  font-size: 10px;
  white-space: wrap;
  color: #535354;
  /* padding: 15px; */
  text-align: center;
  align-content: center;
}

/* Offline Activity Form */

.offlineActivityForm {
  border-radius: 8px;
  padding: 20px;
  max-width: 1020px;
  margin: 20px;
  min-width:350px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.394);
  /* color:white; */
}

.formRow {
  margin-bottom: 15px;
}

.formRow label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  color: black;
}
@media (max-width: 900px) {
  .calendar-header {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .calendar-header {
    width: 90%;
    flex-wrap: wrap;
    gap: 15px;
    padding-bottom: 10px;
  }
  .flexRow {
    flex-direction: column;
    gap: 15px;
    align-items: start;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}


.dropdownMenu {
  display: block;
  position: absolute;
  /* padding: 3px; */
  border: 1px solid black;
  /* background-color: rgb(255, 255, 255); */
  box-shadow: 0 4px 8px rgba(143, 142, 142, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-collapse: collapse;
  gap: 5px;
  padding: 5px;
  
  /* margin-top: 10px; */
}

.day {
  padding: 5px;
  text-align: center;
  border-collapse: collapse;
  border-radius: 4px;
  cursor: pointer;
  /* color: black; */
  border: 1px solid transparent;
  box-sizing: border-box; 
}


.selected {
  background-color: #e71e1e !important;
  color: white;
}



.otherMonth {
  opacity: 0.5;
}

.cardContainer {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  /* padding: 12px; */
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.screenDiv {
  padding: 5px;
  font-size: 10px;
  border: 1px solid rgb(165, 165, 165);
  border-radius: 5px;
  background-color: white;
  position: absolute;
  bottom: -10px;
  left: 30%;
  font-weight: 700;
  color: #00bfff;
}
.screenDropDown {
  position: absolute;
  top: 5px;
  font-size: small;
  border-radius: 5px;
  left: 50%;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #a8a7a7;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
}
.screenDropDown span {
  cursor: pointer;
}
.screenshotMain {
  position: relative;
}
.platformDiv {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px 0px;
  font-size: 9px;
  color: #00bfff;
  font-weight: 700;
  border-radius: 20px;
}
.screenshot {
  position: relative;
  width: 100%;
  height: 90px;
  border-radius: 4px;
}

.viewButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 11px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.screenshotMain:hover .viewButton {
  z-index: 99;
  opacity: 1;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
}

.time {
  font-size: 9px;
}

.progressBarContainer {
  position: relative;
  margin-top: 8px;
  /* background-color: #dddede; */
  border-radius: 4px;
  height: 6px;
}

.progressBar {
  height: 100%;
  background-color: #10b981;
  border-radius: 4px;
}

.progressText {
  font-size: 9px;
  margin-top: 4px;
  text-align: center;
  display: block;
}



.custom_dropdown {
  position: relative;
  /* display: inline-block; */
  /* min-width: 150px; */
}

.dropdown_button {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #c8c6c6;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}


.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  list-style: none;
  padding: 5px;
  z-index: 1000;
}

.dropdown_menu li {
  white-space: nowrap;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}
