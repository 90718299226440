.container{
    width:100%;
    /* position: relative; */
    max-width:1070px;
    display: flex;
    flex-direction: column;
    padding:20px;
}
.main_Heading {
  width: 90%;
  max-width: 1030px;
  font-size: x-large;
  margin: 20px 0px;
}
.formGroup {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  }
  
  .label {
    flex: 1;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
  }
  @media (min-width: 768px) {
    .formGroup {
      width: 45%;
      }
  }