.outlet {
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
} 
.main_Heading {
  width: 90%;
  max-width: 1020px;
  font-size: x-large;
  margin: 20px 0px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 10px;
  overflow: auto;
}

.modal {
  margin-top: 50px;
  padding: 20px;
  border-radius: 8px;
  width: 350px;
  overflow-y: auto;
  height: fit-content;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  line-height: 1;
}

.formGroup {
  margin-bottom: 15px;
}

.error {
  color: red;
  font-size: 12px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.cardTitle {
  font-size: 24px;
  margin-bottom: 10px;
}

.tableResponsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  border: 1px solid red;
  border-radius: 10px;
  /* background-color: #f9fafb;  */
  /* border-radius: 8px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.table {
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;

  th,
  td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ff0000;
  }

  th {
    /* background-color: #f3f4f6; */
    /* color: #555; */
    font-weight: bold;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  td {
    font-size: 0.9rem;
    /* color: #444; */
  }

  tbody tr {
    transition: background-color 0.2s ease;

  
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust the width as needed */
}

.img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
}
@media (max-height:600px){
  .modalOverlay {
    align-items: normal;
  }
  .modal{
    margin-top: 100px;
  }
}