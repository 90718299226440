.tooltip_wrapper {
  display: inline-block;
  position: relative;
}
.tooltip_box {
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: #d90a2c;
  color: white;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 14px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.tooltip_wrapper:hover .tooltip_box {
  opacity: 1;
  visibility: visible;
}

.tooltip_arrow {
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d90a2c;
}
