.checkoutContainer {
  max-width: 500px;
  margin: 30px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.darkMode {
  background-color: #333;
  color: white;
}

.cardForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formTitle {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: #333;
}

.darkMode .formTitle {
  color: white;
}

.submitButton {
  background-color: #ff4b4b;
  color: white;
  font-size: 18px;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #ff3838;
}

.submitButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loading {
  cursor: not-allowed;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .checkoutContainer {
    padding: 15px;
  }

  .formTitle {
    font-size: 20px;
  }

  .submitButton {
    font-size: 16px;
  }
}
