.pricingPlans {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  padding-bottom: 20px;
  max-width: 100%;
}

.plan {
  display: flex;
  flex-direction: column;
  height: "auto";
  justify-content: space-between;
  align-items:center;
  width: 350px;
  padding: 1rem;
  text-align: left;
  flex-grow: 1;
  /* Make the content section grow to fill space */
  justify-content: flex-start;
  /* Align content to the top 
    */
  border-radius: 24px;
  padding: 24px;
  padding-bottom: 60px;
  width: 100% !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.featureLink {
  display: flex;
  flex-direction: column;
  height: "auto";
  width: 350px;
  padding: 1rem;
  text-align: center;
  flex-grow: 1;
  margin-top: auto;
  /* Make the content section grow to fill space */
  justify-content: flex-end;
  color: #dc2626;
}
.currentPlan {
  border-bottom: 2px solid linear-gradient(90deg, #d90a2c 1.05%, #730000) !important;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  text-align: center;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  flex-grow: 1;
  /* Make the content section grow to fill space */
  justify-content: flex-start;
}
.noCurrent {
  border-top: none !important;
  /* border-bottom: 2px solid  linear-gradient(90deg, #d90a2c 1.05%, #730000) !important; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  text-align: center;
  flex-grow: 1;
  /* Make the content section grow to fill space */
  justify-content: flex-start;
}
.planButton {
  font-size: 18px;
  font-weight: 500;
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000);
  margin: 12px 0 0 0;
  padding: 8px 0px 8px 0px;
  border-radius: 15px;
  margin-bottom: 10px;
  border: 2px solid linear-gradient(90deg, #d90a2c 1.05%, #730000);
  width: 100%;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  border: 0 !important;
  color: #fff;
  word-wrap: normal;
}

.planButton:before {
  background-color: #000;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.5s ease-out;
  width: 100%;
  z-index: -1;
}

.planButton:hover {
  box-shadow: 0 0 5px #ffffff4d;
  color: #dc2626;
}

.planDescriptionCard {
  border-bottom-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
  flex-grow: 1;
  /* Make the content section grow to fill space */
  justify-content: flex-start;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}
.planTitle {
  font-size: 26px;
  font-weight: 600;
}

.planDescription {
  font-size: 15px;
}

.planPrice {
  font-size: 32px;
  font-weight: 600;
  margin-top: -10px;
}

.planSubprice {
  margin-top: 10px;
  font-size: 15px;
}

.planFeatures {
  font-size: 1.125rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}

.inputUserField {
}
.card-button {
  font-size: 14px;
  font-weight: 500;
  height: 48;

  background: linear-gradient(90deg, #d90a2c 1.05%, #730000);
}

.hideCurrent::before {
  display: none;
}

.plan::before {
  content: "YOUR PLAN";
  position: absolute;

  left: 50%;
  bottom: 99%;
  transform: translateX(-50%);
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000);
  border: 2px solid #730000 !important;
  border-bottom: none !important;
  width: 101.5%; /* Match border color */
  color: #fff;
  padding: 14px 0px;
  font-size: 16px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  font-weight: bold;
  z-index: 1; /* Ensure it sits above the card */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  text-align: center !important;
}

.seeFeatures {
  display: block;
  color: #dc2626;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  margin: auto !important;
}

.seeFeatures:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .plan {
    max-width: 100%;
  }
}
.glowContainer {
  display: flex;
  flex-direction: column;
  --spread: 60;
}

.glowCard {
  position: relative;
  height: fit-content;
  cursor: pointer;
  transition: all 300ms;
  /* background-color: #1a1a1a; */
  border-radius: 24px;
  z-index: 1;
}

/* .glowCard:hover,
.glowCard:focus-visible {
  z-index: 2;
} */

.glows {
  position: absolute;
  inset: 0;
  pointer-events: none;
  filter: blur(calc(var(--blur) * 1px));
}

.glows::after,
.glows::before {
  content: "";
  background: conic-gradient(
    from 180deg at 50% 70%,
    #f626af 0deg,
    #8228ec 72.0000010728836deg,
    #f626af 144.0000021457672deg,
    #0025ba 216.00000858306885deg,
    #f626af 288.0000042915344deg,
    #fafafa 1turn
  );
  background-attachment: fixed;
  position: absolute;
  inset: 0;
  border: 8px solid transparent;
  border-radius: 12px;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
      #000 0deg,
      #fff,
      #0000 calc(var(--spread) * 1deg)
    );
  mask-composite: intersect;
  mask-clip: padding-box, border-box;
  opacity: var(--active);
  transition: opacity 1s;
}

.glowCard::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  border-radius: 24px;
  pointer-events: none;
  background: #84738c;
  background-attachment: fixed;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from
        calc(
          ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) *
            1deg
        ),
      #ffffff26 0deg,
      white,
      #ffffff26 calc(var(--spread) * 2.5deg)
    );
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active);
  transition: opacity 1s;
}

.glowCard::after {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  border-radius: 12px;
  pointer-events: none;
  background: conic-gradient(
    from 180deg at 50% 70%,
    #f626af 0deg,
    #8228ec 72.0000010728836deg,
    #f626af 144.0000021457672deg,
    #0025ba 216.00000858306885deg,
    #f626af 288.0000042915344deg,
    #fafafa 1turn
  );
  background-attachment: fixed;
  filter: brightness(1.5);
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from
        calc(
          ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) *
            1deg
        ),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg)
    );
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active, 0);
  transition: opacity 1s;
}

.textwitharrow {
  display: inline-flex;
  align-items: center; /* Ensures the text and SVG are vertically aligned */
  font-size: 16px; /* Adjust font size if necessary */
  color: #333; /* Customize text and icon color */
}

.arrowicon {
  margin-left: 8px; /* Add spacing between the text and the icon */
  stroke: currentColor; /* Matches the text color */
}
