.main_container {
    max-width: 1060px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  .main_Heading {
    width: 90%;
    max-width: 1020px;
    font-size: x-large;
    margin: 20px 0px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    text-align: center;
    /* background-color: #f9f9f9; */
  }
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding: 10px;
    overflow: auto;
  }
  
  .modal {
    /* margin-top: 100px; */
    padding: 20px;
    border-radius: 8px;
    /* width: 400px; */
    overflow-y: auto;
    height: fit-content;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    line-height: 1;
  }
  
  .modalContent {
    position: relative;
    min-width: 800px;
    width: 100%;
  }
  
  .header {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroupRow .formGroup {
    flex: 1;
    min-width: 100px;
  }
  .formInnerGroup {
    display: flex;
    justify-content: space-between;
  }
  
  .formGroupCheckbox {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .formGroupSearch {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  .formGroupSearch input {
    height: 40px;
  }
  .checkboxIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: large;
  }
  
  .formGroupCheckbox label {
    margin-left: 5px;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .reportInput {
    width: 100%;
    padding: 10px;
    height: 40px;
    border: 1px solid #727171;
    border-radius: 5px;
    margin-top: 5px;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
  }
  
  .reportInputSelect {
    width: 100%;
    padding: 10px;
    height: 70px;
    border: 1px solid #727171;
    border-radius: 5px;
    margin-top: 5px;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
  }
  
  .reportInput option {
    color: black;
  }
  
  input[type="checkbox"] {
    width: auto;
    margin: 0;
  }
  
  label {
    display: block;
    font-weight: bold;
    font-size: 0.9rem;
  }
  .footer_innerDiv {
    display: flex;
    gap: 10px;
  }
  @media (max-height:600px){
    .modalOverlay {
      align-items: normal;
    }
    .modal{
      margin-top: 100px;
    }
  }
  @media (max-width: 900px) {
    .modalContent {
      min-width: 350px;
      padding: 10px;
    }
  }
  
  .bgDarkMode {
    background-color: #1e1e1e;
    color: #f5f5f5;
  }
  
  .bgLightMode {
    background-color: #ffffff;
    color: #333;
  }
  
  .tableResponsive {
    display: block;
    width: 100%;
    border: 1px solid red;
    border-radius: 10px;
    margin-bottom: 10px;
    /* background-color: #f9fafb;  */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
  
    th,
    td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid #ff0000;
    }
  
    th {
      /* background-color: #f3f4f6; */
      /* color: #555; */
      font-weight: bold;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  
    td {
      font-size: 0.9rem;
      /* color: #444; */
    }
  
    tbody tr {
      transition: background-color 0.2s ease;
  
    }
  }