.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0); /* Dark semi-transparent background */
  display: flex; /* Set as flex container */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  z-index: 1000; /* Ensure it overlays everything */
}

.swiperContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex; /* Flex container */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
}

.slideContainer {
  display: flex; /* Flex container */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  position: relative;
  width: 100%;
  height: 100%; /* Ensures proper spacing */
}

.modalImage {
  width: auto; /* Scales the image proportionally */
  height: auto;
  max-width: 70%; /* Restricts to 70% of viewport width */
  max-height: 70%; /* Restricts to 70% of viewport height */
  object-fit: contain; /* Ensures no distortion */
  align-self: center; /* Centers the image as a flex item */
}


.disabledMonitor {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gray; /* Gray background */
  width: 70%; /* Same width as your image */
  height: 70%; /* Same height as your image */
  color: white;
  font-size: 1.5rem; /* Adjust font size as needed */
  text-align: center;
  border-radius: 8px; /* Optional: Add rounded corners */
  margin: auto; /* Center horizontally and vertically */
}

.arrowButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #0000000d;/* Dark background for better visibility */
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  z-index: 1100; /* Higher than other elements */
}

.leftArrow {
  left: calc(15% - 40px); /* Adjust to be outside the image's left edge */
}

.rightArrow {
  right: calc(15% - 40px); /* Adjust to be outside the image's right edge */
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: white;
  font-size: 32px;
  cursor: pointer;
  z-index: 1200; /* Ensure it's on top of everything */
}
