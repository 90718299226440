/* MyComponent.css */
.customFont {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  @keyframes borderAnimation {
    0% {
      border-width: 1px;
      transform: scale(1);
      opacity: 1;
    }
    100% {
      border-width: 0px;
      transform: scale(1.2);
      opacity: 0;
    }
  }
  
  .button-container {
    position: relative;
    display: inline-block;
  }
  
  .button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 2px solid transparent; /* Initial border */
    border-radius: 0.375rem;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: none;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
    transition: all 0.1s ease;
    z-index: 1; /* Ensure the button is above the animated border */
  }
  
  .button.dark {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1));
  }
  
  .button.light {
    background: linear-gradient(to right, rgba(109, 109, 109, 0.2), rgba(255, 255, 255, 0.1));
  }
  
  .borderaffectdark {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid rgba(255, 255, 255, 0.5); /* Border color */
    border-radius: 0.375rem;
    animation: borderAnimation 3s infinite ease-out;
    pointer-events: none; /* Ensure it doesn't block clicks */
  }
  .borderaffectlight {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid rgba(14, 13, 13, 0.5); /* Border color */
    border-radius: 0.375rem;
    animation: borderAnimation 3s infinite ease-out;
    pointer-events: none; /* Ensure it doesn't block clicks */
  }
  
  