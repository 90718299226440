.heroSection {
    padding: 30px 16px;
    font-family: 'Roboto', sans-serif;
  }

  .gallary{
    font-family: 'Roboto', sans-serif;
  }
  
  .heroContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 16px;
  }
  
  .heroText {
    width: 100%;
    padding: 16px;
  }
  
  .heroText p {
    font-size: 1.125rem;
    margin-bottom: 32px;
  }
  

   
  
  .heroAnimation {
    width: 100%;
    padding: 16px;
  }
  
  @media (min-width: 768px) {
    .heroText {
      width: 60%;
    }
  
    .heroAnimation {
      width: 40%;
    }
  }
   
  