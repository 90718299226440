
.contactus {
    padding: 5rem 32px;
    max-width: 1200px;
  margin: 0 auto;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .formContainer,
  .info {
    max-width: 100%;
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .form {
    border-radius: 30px;
    padding: 1rem;
    border: 1px solid #CC0927;
    text-align:center;
  }
  .contactForm {
    border-radius: 30px;
    text-align:center;
  }
  
  .formGroup {
    margin-bottom: 2.5rem;
    
  }
  .formGroupContact{
    margin-bottom: 1rem;
  }
  
  .label {
    display: flex;
    align-items: center;
    justify-content:start;
    gap:10px;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .introText {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .infoItem {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .contactIcon {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #ff0000;
  }
  
  .infoTitle {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  @media (min-width: 768px) {
    .contactus{
      padding: 5rem 64px;
    }
    .content {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  
    .formContainer,
    .info {
      margin-bottom: 0;
    }
  
    .info {
      text-align: left;
    }
    .form{
      padding: 4rem;
    }
  }
  @media (min-width:1300px) {
    .contactus{
      padding: 5rem 18px;
    }
  }
  