.notfoundError {
  padding: 50px 15px;
  background-color: black;
  min-height: 100vh;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.errorContent {
  flex: 1;
}

.errorContent h2 {
    -webkit-text-stroke: 2px #d90a2c;
    -webkit-text-fill-color: #0000;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.errorContent h1 {
  font-size: 48px;
  font-weight: 900;
  color: white;
}

.errorContent p {
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
}



.errorImg {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorImg img {
  max-width: 100%;
  height: auto;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .contentWrapper {
    flex-direction: column;
  }

  .errorContent h2 {
    font-size: 28px;
  }

  .errorContent h1 {
    font-size: 36px;
  }

  .errorContent p {
    font-size: 14px;
  }


}

@media (max-width: 576px) {
  .errorContent h2 {
    font-size: 24px;
  }

  .errorContent h1 {
    font-size: 32px;
  }

  .errorContent p {
    font-size: 12px;
  }


}
