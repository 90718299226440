


.features-container{
  padding-top: 36px;
  padding-left: 20px;
  padding-right: 20px;
}
.feature-header{
  display: flex;
  flex-direction: column;

  flex-wrap: nowrap;
}
.comparison-table-heading{
  font-size: 32px;
  text-align: center;
  font-weight: 'bold';
}
.back-button{
 
}
.comparison-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  overflow-x: auto;
  height: 100%;
  margin-top: 0px !important;
  padding: 20px 50px 20px 50px;
}

.comparison-table table {
  width: 100%;
  /* border: 1px solid #ddd; */
}


.comparison-table td {
  padding: 15px 10px;
  text-align: center;
  font-size: 14px;
}
.comparison-table td:last-child {
 
  border-right: none !important;
}
.comparison-table .table-data .table-h{
  width: 10%;
}

.comparison-table thead{
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid #dc2626;
  /* position: sticky;
  top: 0; 
  z-index: 1; */
}
.feature-name{
  text-align: left !important;
  font-size: 16px;
  padding: 10px;
}
.signup-btn,
.demo-btn {
  margin-top: 5px;
  padding: 8px 15px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signup-btn {
 padding: 9px 30px;

}

.demo-btn {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.comparison-table td {
  font-size: 16px;
}

@media (max-width: 768px) {
  .comparison-table table {
    font-size: 14px;
  }

  .signup-btn,
  .demo-btn {
    position: relative;
    left: 0;
    white-space: nowrap;
    margin-left: 0;
  }
}
@media (max-width: 1024px){
  .comparison-table{
    padding: 0;
  }
}
