
.heroSection {
  padding: 30px 16px;
  /* background-color: #000000; */
}

.heroContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 16px;
}

.heroText {
  width: 100%;
  padding: 16px;
}

.heroAnimation {
  width: 100%;
  padding: 16px;
}
.sub-heading{
  font-size: 22px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .heroText {
    width: 50%;
  }

  .heroAnimation {
    width: 50%;
  }
} 
 
.productivityText {
    padding: 10px 20px;
    border: 1px solid #e53e3e;
    border-radius: 12px;
    transition: transform 0.3s ease, background-color 0.3s ease,
      box-shadow 0.3s ease;
  }
  .productivityText:hover {
    /* color: white; */
    /* background: linear-gradient(90deg, #e95f76 1.05%, #db4949); */
    background: linear-gradient(90deg, #CC0927 0%, #7E0105 100%);
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  color:white
  }
  
  .productivityText span {
    font-size: 14px;
  }


 