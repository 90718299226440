@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Scrollbar Base */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Scrollbar Thumb */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 10px;
  transition: all 0.3s ease;
}
/* Scrollbar area (replaces track) */
::-webkit-scrollbar-track-piece {
  background: var(--scrollbar-area-color);
  border-radius: 0;
}
/* Light Theme */
.light-theme {
  --scrollbar-area-color: #F2F4F7;
  --scrollbar-thumb-color: linear-gradient(180deg, #CC0927 0%, #A50516 30%, #92030E 60%, #7E0105 100%);
  --scrollbar-thumb-hover-color: linear-gradient(180deg, #A50516 0%, #92030E 30%, #7E0105 100%);
  --scrollbar-button-color: #F2F4F7;
}

/* Dark Theme */
.dark-theme {
  --scrollbar-area-color: #1A1A1A;
  --scrollbar-thumb-color: linear-gradient(180deg, #CC0927 0%, #A50516 30%, #92030E 60%, #7E0105 100%);
  --scrollbar-thumb-hover-color: linear-gradient(180deg, #A50516 0%, #92030E 30%, #7E0105 100%);
  --scrollbar-button-color: #1A1A1A;
}

/* Light Theme */
.light-theme {
  --file-input-bg-color: rgb(237, 234, 234);
  --file-input-color: black;
  --autofill-bg-color:#FFFFFF;
  --autofill-color: black;
}

/* Dark Theme */
.dark-theme { 
  --file-input-bg-color:#555657;
  --file-input-color: white;
  --autofill-bg-color:#212529;
  --autofill-color: white;
}


:root {
  --z-index-dropdown: 1000;
  --z-index-modal: 2000;
  --z-index-toast: 3000;
}

body {
  font-family: "Fira Sans", sans-serif;
  overflow-x: hidden;
  padding: 0 !important;
  overflow-x: hidden;
  width: 100%;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100%;
}

.fontRoboto{
  font-family: 'Roboto', sans-serif;
}
.fontPoppins{
  font-family: "Poppins", sans-serif;
}


.home {
  background-color: transparent;
  position: relative;
}
#particaljs canvas {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* button */

.heroButton {
  background: linear-gradient(90deg, #d90a2c 1.05%, #730000);
  border: 0;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 18px;
  position: relative;
  text-transform: capitalize;
  transition: all 0.5s ease-out;
  z-index: 1;
}

.heroButton:before {
  background-color: #000;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: all 0.5s ease-out;
  width: 100%;
  z-index: -1;
}

.heroButton:hover {
  box-shadow: 0 0 5px #ffffff4d;
  color: #ff0000;
}

.heroButton:hover:before {
  background-color: #000;
  height: 350%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: -1;
}

.main-heading {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 16px;
}
.sub-heading {
  font-size: 18px;
  font-weight: 700;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}
@media (max-width: 768px) {
  .main-heading {
    font-size: 1.8rem;
  }
  .sub-heading {
    font-size: 15px;
  }
}
/* Navbar */
.sticky-navbar {
  background-color: #212529;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid black;
}
.dark_mode {
  background-color: #212529;
  color: #ffffff;
}
.light_mode {
  background-color: #dfdfdf;
  color: #000000;
}
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top .logo {
  display: flex;
  align-items: center;
}

.header-top .logo svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.header-top .logo span {
  font-size: 1.25rem;
  font-weight: 600;
}

.nav-toggle-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

nav a {
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;
}

nav a:hover {
  color: #ff0000;
}

@media (max-width: 1050px) {
  header {
    display: flex;
    flex-direction: column;
  }
  .header-top {
    width: 100%;
  }

  .nav-toggle-btn {
    display: block;
  }

  nav {
    flex-direction: column;
    gap: 16px;
    display: none;
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  nav.visible {
    display: flex;
    position: absolute;
    top: 63px;
    padding: 20px 0;
    width: 100%;
    animation: slideDown 0.3s ease-in-out forwards;
    opacity: 1;
  }
  .navdark {
    background-color: #1a1a1a;
    color: white;
  }
  .navlight {
    background-color: #bbbbbb;
    color: black;
  } 
}

.footer {
  /* background-color: #000000; */
  padding: 2rem 1rem;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-divider {
  border-color: #333333;
  margin-bottom: 1rem;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.footer-link {
  text-decoration: none;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #ff0000;
}

.social-links {
  display: flex;
  gap: 10px;
}

.footer-icon:hover {
  transform: scale(1.1) rotate(360deg); 
  color: #f00;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); 
}

.footer-icon {
  transition: transform 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  width: 1.5rem;
  height: 1.5rem;
  color: #ff0000;
}

@media (min-width: 800px) {
  .footer-content {
    flex-direction: row;
    align-items: center;
  }

  .footer-links {
    flex-direction: row;
  }
}

.primaryFooter{
  font-family: 'Roboto', sans-serif;
}

/* Log In */
.login-container {
  /* width: 100vw; */
  min-height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-attachment: fixed;
}

.login-box {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 15px;
}
.signup-box {
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 15px;
}
.input-group {
  margin-bottom: 15px;
}

.input-label {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 8px;
}

.input-field {
  width: 100%;
  padding: 10px;
  height:40px;
  color: #fff;
  border: 1px solid #666;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #ff0000;
}

.file-input::-webkit-file-upload-button {
  appearance: none; /* Remove default button */
  background-color: var(--file-input-bg-color); /* Custom button color */
  color: var(--file-input-color);
  border: none;
  margin-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 6px;
}

.file-input::-webkit-file-upload-button:hover {
  background-color: var(--file-input-bg-color); /* Hover effect */
}


.forgot-password {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0px;
}

.forgot-link {
  font-size: 0.875rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-link:hover {
  color: #e64444;
  text-decoration: underline;
}

.terms-text {
  text-align: left;
  color: #ccc;
  font-size: 0.75rem;

}

.terms-link {
  text-decoration: none;
  transition: color 0.3s ease;
}

.terms-link:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .login-box {
    padding: 15px;
  }
  .signup-box{
    padding: 15px;
  }

  .login-title {
    font-size: 2rem;
  }

  .input-field {
    padding: 10px;
  }
}

/* Sign Up  */

.custom_dropdown {
  position: relative;
  /* display: inline-block; */
  /* min-width: 150px; */
}

.dropdown_button {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid #eae9e9;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}


.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  list-style: none;
  padding: 5px;
  z-index: 1000;
}

.dropdown_menu li {
  white-space: nowrap;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0a9;
}
.domain-main {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.domain-input {
  padding-right: 150px;
}
.http {
  padding: 0 5px;
}
.domain {
  position: absolute;
  right: 5px;
}

/* Custom class to hide the scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}



/* Example CSS */
.quill-dark-theme .ql-toolbar .ql-stroke {
  stroke: #ffffff; /* White icons for dark theme */
}

.quill-dark-theme .ql-toolbar .ql-fill {
  fill: #ffffff; /* White fill for dark theme */
}
.quill-dark-theme {
  border-radius: 10px !important;
}
.quill-light-theme{
  border-radius: 10px !important;
}

.quill-light-theme .ql-toolbar .ql-stroke {
  stroke: #000000; /* Black icons for light theme */
}

.quill-light-theme .ql-toolbar .ql-fill {
  fill: #000000; /* Black fill for light theme */
}
.customFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
/* Remove border from ReactQuill editor */
.no-border .ql-container {
  border: none !important; /* Removes the border around the text editor */
  padding: 0.5rem; /* Optional: Adjust padding for better spacing */
}

.no-border .ql-toolbar {
  border: none !important; /* Removes the toolbar border */
}

.no-border .ql-editor {
  border: none !important; /* Removes border inside the editor */
  padding: 0 !important; /* Removes any internal padding */
}
.custom-border .ql-container{
  border: 1px solid #848181 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.custom-border .ql-toolbar{
  border: 1px solid #848181 !important;
  border-bottom: none !important;;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.custom-border .ql-editor{
  border: none !important;
}
.lightEditor .gjs-one-bg{
  background: rgb(244, 241, 241) !important;
  color: #000 !important;
}
.gjs-editor-cont{
  height: 80vh !important;
}
.darkEditor .gjs-one-bg{
  background: #212529 !important;
  color: #fff !important;
}

.app-container {
  display: flex;
  padding: 20px;
}

.sidebar {
  width: 200px;
  background: #f4f4f4;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h3 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.sidebar button {
  display: block;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
}

.canvas-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

canvas {
  border: 1px solid #ddd;
}
.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% transparency */
}


.dark-input:-webkit-autofill,
.dark-input:-webkit-autofill:hover, 
.dark-input:-webkit-autofill:focus{
  background:  #212529 !important;
  color: white !important;
}
