.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
 
.modalContent {
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh; 
  overflow-y: auto;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.closeButton {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.formGroup {
  margin-bottom: 15px;
}


.formGroupRow .formGroup {
  flex: 1; 
  min-width: 100px; 
}
.formInnerGroup{
  display: flex;
  justify-content: space-between;
}

.formGroupCheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.formGroupSearch{
  position:relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.formGroupSearch input{
  height: 30px;
}
.checkboxIcon{
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: large;
}

.formGroupCheckbox label {
  margin-left: 5px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}



.reportInput {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  box-sizing: border-box; 
  overflow-y: auto;
  background-color: transparent;
}

.reportInput input[type="checkbox"] {
  width: auto;
  margin: 0;
}

.reportInput label {
  display: block;
  font-weight: bold;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .modalContent {
    width: 100%;
    padding: 15px;
  }
}
