.outlet {
  max-width: 1060px;
}
.main_Heading {
  width: 90%;
  max-width: 1030px;
  font-size: x-large;
  margin: 20px 0px;
}
.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 10px;
}

.chartWrapper {
  flex: 1 1 48%;
  max-width: 48%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  transition: transform 0.3s ease;
}

.chartWrapper:hover {
  transform: scale(1.02);
}

.chartTitle {
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
}

@media (max-width: 900px) {
  .chartWrapper {
    flex: 1 1 100%;
    max-width: 100%;
  }
}


.custom_dropdown {
  position: relative;
  /* display: inline-block; */
  /* min-width: 150px; */
}

.dropdown_button {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 2px 10px;
  border: 1px solid #eae9e9;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}


.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  list-style: none;
  padding: 5px;
  z-index: 1000;
}

.dropdown_menu li {
  white-space: nowrap;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0a9;
}
