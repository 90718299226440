.featureMain {
  padding: 40px 20px;
}

.featureContainer {
  display: flex;
  justify-content: space-between;
}

.featureContainerDiv {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  border: 1px solid #e53e3e;
  border-radius: 12px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
}
.featureContainerDiv:hover {
  background: linear-gradient(90deg, #CC0927 0%, #7E0105 100%);
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.featureContainerDiv:hover .featureIcon {
  fill: white;
}
.featureContainerDiv:hover .icon {
  background: white;
}

.featureContainerDiv:hover .iconDark {
 color: white;
}

@media (max-width: 768px) {
  .featureContainer {
    flex-direction: column;
  }
  .featureContainerDiv {
    width: auto;
  }
  .featureContainer {
    gap: 20px;
  }
}
