.pricingPlans {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}
@media (max-width: 1108px) {
  .pricingPlans {
    justify-content: center;
  }
}
.plan {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 350px;
  padding: 1rem;
  text-align: center;
}
.planTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.planDescription {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.planPrice {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.planSubprice {
  font-size: 1rem;
}

.planFeatures {
  font-size: 1.125rem;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .plan {
    max-width: 100%;
  }
}
.glowContainer {
  display: flex;
  flex-direction: column;
  /* --spread: 60; */
}

.glowCard {
  position: relative;
  min-height: 300px;
  cursor: pointer;
  transition: all 300ms;
  /* background-color: #1a1a1a; */
  border: 1px solid #e53e3e;
  border-radius: 30px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  z-index: 1;
}
.glowCard:hover {
  /* color: white; */
  /* background: linear-gradient(90deg, #e95f76 1.05%, #db4949); */
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.glowCard:hover,
.glowCard:focus-visible {
  z-index: 2;
}

.glows {
  position: absolute;
  inset: 0;
  pointer-events: none;
  filter: blur(calc(var(--blur) * 1px));
}

.glows::before,
.glows::after {
  content: "";
  background: conic-gradient(
    from 180deg at 50% 70%,
    #f626af 0deg,
    #8228ec 72.0000010728836deg,
    #f626af 144.0000021457672deg,
    #0025ba 216.00000858306885deg,
    #f626af 288.0000042915344deg,
    #fafafa 1turn
  );
  background-attachment: fixed;
  position: absolute;
  inset: 0;
  border: 8px solid transparent;
  border-radius: 12px;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
      #000 0deg,
      #fff,
      #0000 calc(var(--spread) * 1deg)
    );
  mask-composite: intersect;
  mask-clip: padding-box, border-box;
  opacity: var(--active);
  transition: opacity 0s;
}


.currentPlanGlowCard::after{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.currentPlanGlowCard::before{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.glowCard::before {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  border-radius: 30px;
  pointer-events: none;
  background: #84738c;
  background-attachment: fixed;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from
        calc(
          ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) *
            1deg
        ),
      #ffffff26 0deg,
      white,
      #ffffff26 calc(var(--spread) * 2.5deg)
    );
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active);
  transition: opacity 1s;
}

.glowCard::after {
  content: "";
  position: absolute;
  inset: 0;
  border: 2px solid transparent;
  border-radius: 30px;
  pointer-events: none;
  background: conic-gradient(
    from 180deg at 50% 70%,
    #f626af 0deg,
    #8228ec 72.0000010728836deg,
    #f626af 144.0000021457672deg,
    #0025ba 216.00000858306885deg,
    #f626af 288.0000042915344deg,
    #fafafa 1turn
  );
  background-attachment: fixed;
  filter: brightness(1.5);
  mask: linear-gradient(#0000, #0000),
    conic-gradient(
      from
        calc(
          ((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) *
            1deg
        ),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg)
    );
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active, 0);
  transition: opacity 1s;
}
