.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  margin: 15px auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.394);
  }
  
  .formGroup {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .label {
    font-weight: bold;
    margin-right: 10px;
    flex: 1;
  }
  
  .input {
    width: 100%;
    border: 1px solid #ccc;
    height: calc(2.25rem + 2px);
    font-weight: 400;
    font-size: .875rem;
    padding: .625rem .6875rem;
    border-radius: 10px;
    color: #000000;
  }
  
  .example {
    margin-left: 10px;
    color: #888;
    font-size: 0.875rem;
  }
  


  