.navbarWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  /* background-color: #212529; */
  padding: 10px 20px;
}
.menuContainer2 {
  display: flex;
  align-items: center;
  gap: 25px;
}

.menuContainer {
  display: flex;
  align-items: center;
  gap: 25px;
}

.menuItem {
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background: none;
  position: relative;
  transition: color 0.2s ease;
}

.menuItem:hover {
  color: red;
}

.iconButton {
  position: relative;
  font-size: larger;
}

.notificationCount {
  position: absolute;
  top: -5px;
  right: 0%;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}

.dropdownWrapper {
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  z-index: 100;
}

.dropdownContent {
  position: absolute;
  top: 30px;
  right: 0;
  color: black;
  background-color: white;
  border: 1px solid #7a7979;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* min-width: 200px; */
  padding: 10px 10px;
  z-index: 1000;
}
.dropdownContent2 {
  position: absolute;
  top: 40px;
  right: 0;
  color: black;
  background-color: white;
  border: 1px solid #7a7979;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  padding: 10px 0px;
  z-index: 10000;
}
.dropdownItem {
  display: flex;
  color: black;
  align-items: center;
  padding: 1px 5px;
  cursor: pointer;
  transition: background 0.2s ease;
  border-radius: 10px;
  margin:5px;
}

.dropdownItem:hover {
  
  background-color: #d8d8d8;
}


.profileWrapper {
  display: flex;
  align-items: center;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* margin-right: 10px; */
}
.profileImg2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.profileName {
  font-size: 1rem;
}
.profileName:hover {
  color: red;
}
.dropdownTitle {
  font-weight: bold;
  margin-bottom: 10px;
  /* padding-left: 15px; */
}

.notificationText {
  display: flex;
  gap: 5px;
  align-items: center;
  /* padding-left: 15px; */
  font-size: 0.9rem;
  color: #555;
}
.navLink{
  position: relative;
  transition: all 0.5s ease;
}
.navLink:hover{
  color: red;
}
.navLink::after{
  background-color: #ff0000;
    bottom: -5px;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    top: 34px;
    transition: all 1s ease;
    width: 0;
    border-radius: 20px;
}
.navLink.active::after{
  width: 100%;
}
.active{
  color: red;
}


.sticky_navbar {
  padding: 16px 32px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: stickey;
  top: 0;
  z-index: 1000;
  width: 100%;
}
.dark_mode {
  background-color: #212529;
  color: #ffffff;
}
.light_mode {
  background-color: #dfdfdf;
  color: #000000;
}
.header{
  border-bottom: 1px solid gray;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.header_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_top .logo {
  display: flex;
  align-items: center;
}

.header_top .logo svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.header_top .logo span {
  font-size: 1.25rem;
  font-weight: 600;
}

.nav_toggle_btn {
  background: none;
  border: none;
  /* font-size: 24px; */
  cursor: pointer;
  display: none;
  
}
.navLinkMain{
  display:flex;
  gap: 24px;
  margin-left: 32px;
  padding-bottom: 12px;
}
.nav {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav a {
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease;
}

.nav a:hover {
  color: #ff0000;
}
.mobileNavbar {
  display: none;
}
@media (max-width: 750px) {
  .mobileNavbar {
  display: block;
}
  .logobar{
    display: none;
  }
  .profileName{
    display: none;
  }
  .menuContainer{
    display: none;
  }
  .navLinkMain{
    display: none;
  }
  .header_top {
    width: 100%;
  }

  .nav_toggle_btn {
    display:flex;
    gap: 10px;
    align-items: center;
  }

  .nav {
    flex-direction: column;
    gap: 16px;
    display: none;
  }

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .nav.visible {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 52px;
    left: 0;
    padding: 20px 30px;
    width: 100%;
    animation: slideDown 0.3s ease-in-out forwards;
    opacity: 1;
  }
  .navdark {
    background-color: #1a1a1a;
    color: white;
  }
  .navlight {
    background-color: #bbbbbb;
    color: black;
  } 
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index:9999;
  padding:10px;
  overflow: auto;
}

.modal {
  margin-top: 100px;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  overflow-y: auto;
  height: fit-content;
}
.reportModal {
  margin-top: 40px;
  padding: 20px;
  border-radius: 8px;
  width: auto;
  overflow-y: auto;
  height: fit-content;
}

.toggleContainer {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  border-radius: 20px;
  /* padding: 5px; */
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  /* color: #fff; */
  background: transparent;
  border: none;
  outline: none;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
}

.toggleButton.inactive:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.toggleButton.active {
  border: 1px solid gray;
  border-radius: 50%;
}
