.howitworks {
  padding: 4rem 0;
  font-family: 'Roboto', sans-serif;
}

.intro {
  display: flex;
  margin-bottom: 5rem;
}

.introText {
  width:60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.getStartedBtn {
  padding: 0.5rem 2rem;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.getStartedBtn:hover {
  background-color: #4a5568;
}

.introImage {
  width:40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.image {
  max-width: 100%;
  border-radius: 0.75rem;
}

.steps {
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.step {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  padding: 20px;
  border: 1px solid #e53e3e;
  border-radius: 12px;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
}

.step:hover {
  /* background: linear-gradient(90deg, #e95f76 1.05%, #db4949); */
  background-color: #f01e2cd3;
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}
.step:hover .workIcon {
  color: white;
}
.workIcon {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #ff0000;
}

.stepDescription {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .intro {
    flex-direction: column;
  }

  .steps {
    flex-direction: column;
    gap: 2rem;
  }

  .step {
    width: 100%;
  }
  .introImage{
    width: 100%
  }
  .introText{
    width: 100%
  }
}
