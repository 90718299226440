.main_Heading {
  width: 90%;
  max-width: 1020px;
  font-size: x-large;
  margin: 20px 0px;
}
.tableResponsive {
    display: block;
    width: 100%;
    border: 1px solid red;
    border-radius: 10px;
    margin-bottom: 10px;
    /* background-color: #f9fafb;  */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
  
    th,
    td {
      padding: 12px 15px;
      text-align: left;
      border-bottom: 1px solid #ff0000;
    }
  
    th {
      /* background-color: #f3f4f6; */
      /* color: #555; */
      font-weight: bold;
      font-size: 0.9rem;
      text-transform: uppercase;
    }
  
    td {
      font-size: 0.9rem;
      /* color: #444; */
    }
  
    tbody tr {
      transition: background-color 0.2s ease;
  
    }
  }