/* Swiper pagination dots styling */
.swiper-pagination {
    position: relative !important;
    margin-top: 15px;
}

.swiper-pagination-bullet {
    background: #ccc !important;
    width: 10px;
    height: 10px;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background: red !important; /* Active dot color */
    width: 12px;
    height: 12px;
}
