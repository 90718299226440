.container {
  padding: 20px;
}

.title {
  font-size: 2rem;
  color: red;
  margin-bottom: 20px;
  text-align: center;
}

.content {
  line-height: 1.6;
}

.content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: red;
}

.content p {
  font-size: 1rem;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
    margin: 10px;
  }

  .title {
    font-size: 1.8rem;
  }

  .content h2 {
    font-size: 1.3rem;
  }

  .content p {
    font-size: 0.9rem;
  }
}
