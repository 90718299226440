:root {
  --background-dark: #1c1c1e;
  --background-light: #2c2c2e;
  --accent-color: #f01e2cd3;
  --text-color: #ffffff;
  --muted-text: #a1a1a1;
  --border-color: #3a3a3c;
}

.outlet {
  padding: 20px;
}
.main_Heading{
  width: 90%;
  max-width: 1020px;
  font-size: x-large;
  margin: 20px 0px;
}

.calendar-section {
  flex-grow: 1;
  /* padding: 20px; */
}

.calendar-inner-section {
  overflow-x: auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  margin: 30px 0px;
  /* padding: 10px; */
  width: 800px;
  /* border-bottom: 1px solid black; */
}

.month-div {
  display: flex;
  align-items: center;
  gap: 5px;
}



.calendar-table {
  max-width: 800px;
  height: 550px;
  border-collapse: collapse;
  border: 1px solid red;
  margin-bottom: 30px;
}

.day-header {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  border: 2px solid var(--border-color);
}

.calendar-cell {
  padding: 10px;
  height: 80px;
  border: 1px solid var(--border-color);
  transition: background-color 0.3s ease;
}



.calendar-inner-cell {
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 80px;
  height:100%;
}

.result {
  align-content: end;
  min-width: 100px;
  color: var(--background-dark);
}

@media (max-width: 768px) {
  .calendar-header {
    flex-direction: column;
    gap: 15px;
  }

  .calendar-cell {
    height: 60px;
  }

  .calendar-table {
    font-size: 14px;
  }
}



.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownMenu {
  display: block;
  position: absolute;
  background-color: rgb(0, 0, 0);
  color: var(--text-color);
  box-shadow: 0 4px 8px rgba(143, 142, 142, 0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdownMenu li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdownMenu li:hover {
  background-color: var(--accent-color);
}

.trackedTimeDropDown {
  position: absolute;
  top: 90%;
  font-size: small;
  border-radius: 5px;
  left: 35%;
  padding: 10px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.582);
  color: black;
  white-space: nowrap;
  z-index: 9999;
}


.custom_dropdown {
  position: relative;
  /* display: inline-block; */
  /* min-width: 150px; */
}

.dropdown_button {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #c8c6c6;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}


.dropdown_menu {
  position: absolute;
  top: 100%;
  left: 0;
  /* background-color: #fff; */
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-top: 5px;
  list-style: none;
  padding: 5px;
  z-index: 1000;
}

.dropdown_menu li {
  white-space: nowrap;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}